<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행단계 -->
          <c-select
            type="search"
            stepperGrpCd="SAI_COMPLIANCE_EVAL_TABLE_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            label="LBLSTEPPROGRESS"
            name="saiComplianceEvalTableStepCd"
            v-model="searchParam.saiComplianceEvalTableStepCd"
          ></c-select>
        </div>
          <!-- 제·개정일시 -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="제·개정일시"
            name="period"
            v-model="searchParam.period"
          />
        </div> -->
      </template>
    </c-search-box>
    <!--법규등록부 및 준수평가표 목록-->
    <c-table
      ref="table"
      title="LBL0010051"
      tableId="processManual"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--등록-->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="linkClick" />
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'compliance-eval-table',
  data() {
    return {
      searchParam: {
        plantCd: '',
        saiComplianceEvalTableStepCd: null,
        // period: [],
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
          },
          {
            name: 'evalDt',
            field: 'evalDt',
            //평가기간
            label: 'LBL0010052',
            align: 'center',
            style: 'width:180px',
            sortable: true,
          },
          {
            name: 'requirementName',
            field: 'requirementName',
            //법규/기타 요건명
            label: '준수평가 제목',
            align: 'left',
            style: 'width:400px',
            type: "link",
          },
          {
            name: 'saiComplianceEvalTableStepName',
            field: 'saiComplianceEvalTableStepName',
            //진행상태
            label: 'LBLPROGRESS',
            style: 'width:100px',
            align: 'center',
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: 'LBL00001609',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'reviewUserName',
            field: 'reviewUserName',
            //검토자
            label: 'LBLREVIEWER',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'approvalUserName',
            field: 'approvalUserName',
            //승인자
            label: 'LBLAPPROVER',
            style: 'width:100px',
            align: 'center',
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            //작성자
            label: 'LBLREGISTER',
            style: 'width:100px',
            align: 'center',
          },
          // {
          //   name: 'regDtStr',
          //   field: 'regDtStr',
          //   label: '제·개정일시',
          //   style: 'width:100px',
          //   align: 'center',
          // },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.evalTable.complianceEvalTable.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      // this.grid.data = [
      //   { id: '1', revisionNum: 'v0.1', plantName: '사업장1', col1: 'XX 부서 요건명(산업안전보건법, 에너지이용 합리화법, 도시가스사업법)', col2: '김검토', col3: '이승인', regDtStr: '2022-11-17', regUserName: '최수능', }
      // ]
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(row) {
      this.popupOptions.target = () => import(`${"./complianceEvalTableDetail.vue"}`);
      this.popupOptions.title = 'LBL0010054';   //법규등록부 및 준수평가표
      this.popupOptions.param = {
        saiComplianceEvalTableId: row ? row.saiComplianceEvalTableId : null,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
